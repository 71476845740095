// 客製化樣式

// Global
.swiper-box {
	position: relative;
}


// Navigation
%swiper-button {
	--swiper-navigation-size: #{rem-calc(40)};

	width: var(--swiper-navigation-size);
	
	background-color: $white;
	border-radius: 100%;
	box-shadow: ps-shadow( $shadow-angle, 3px, 0, 8px, rgba($shadow-color, 0.25), false);

	&::after {
		@include font-icon-style;

		font-size: rem-calc(20);
	}

	@include breakpoint-hover {
		transition: box-shadow 0.3s;

		&::before {
			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			transform: scale(0);
			border: 1px solid $primary-color;
			transition: opacity 0.3s, transform 0.3s;
		}
		
		&:hover,
		&:focus {
			box-shadow: ps-shadow( $shadow-angle, 3px, 0, 30px, rgba($primary-color, 0.45), false);

			&::before {
				transform: scale(1);
			}
		}

		&:active {
			&::before {
				transform: scale(1.5);
				transition-duration: 0.1s;
			}
		}
	}

	&.border {
		background: rgba($black, 0.1);
		border: 1px solid $white;
		box-shadow: ps-shadow( $shadow-angle, 3px, 0, 30px, rgba($shadow-color, 0.25), false);

		@include breakpoint-hover {
			&::before {
				border-color: $white;
			}

		}
	}
}

.swiper-button-prev {
	@extend %swiper-button;

	&::after {
		@include font-icon-arrow-left(true);
	}
	
}

.swiper-button-next {
	@extend %swiper-button;

	&::after {
		@include font-icon-arrow-right(true);
	}
}




// Pagination
.swiper-pagination {
	width: 100%;
}

.swiper-pagination-bullet {
	width: 12px;
	height: 12px;
	margin-right: rem-calc(6);
	margin-left: rem-calc(6);
	border: 1px solid $primary-color;
	background: $white;
	opacity: 1;

	&-active {
		background: $primary-color;
	}
}




// Effect: Fade
.swiper-container-fade {
	.swiper-slide {
		// Fade Not Active
		&:not(.swiper-slide-active) {
			// opacity: 0 !important;
			pointer-events: none;
		}
	}
}
