// Swiper 如果啟用 Loop 有可能造成 LCS（在第一個螢幕範圍內）
// 相關討論：https://github.com/nolimits4web/swiper/issues/4076
// 解法參考：https://github.com/nolimits4web/swiper/issues/4076#issuecomment-823584824
@mixin swiper-fixed-loop-lcs {
    // .swiper-container {
    //     position: relative !important;
    // }

    .swiper-slide-duplicate:first-child {
        position: absolute !important;
        width: 100% !important; /* seems like you can remove this line but I didn't test without it */
        left: 0 !important;
    }

    .swiper-wrapper::before {
        content: '' !important;
        min-width: 100% !important;
    }
}
