// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：通用於所有SCSS/SASS變數檔案
//      - 變數、mixin、function
//      - 所有單元都建議須要載入之樣式
//      - 避免寫實際的樣式於該檔案內（包含檔案內 @import 之其它檔案）

@charset 'utf-8';
// --------------------------------------------------------
// Vendor
// --------------------------------------------------------
@import '../vendor/font/icons'; // 載入Icon Font 變數
@import '../foundation/foundation'; // Foundation 變數 & Function

@import '../vendor/trig'; // 三角函數



// --------------------------------------------------------
// Variable(1/2)
// --------------------------------------------------------
// Breakpoint 用
$xs: 375;
$xs-down: ($xs - 1) down;

// 主要顏色
$primary-color: $primary-color; // Foundation 主顏色變數
$primary: $primary-color; // Bootstrap 主顏色變數

$gray-light: #f6f6f6;

// 文字顏色
$primary-text: $body-font-color; // Foundation 文字顏色變數
$primary-tip: lighten($body-font-color, 30%); // 提示文字顏色

// 連結顏色
$primary-link: #3795ff;
$primary-hover: $anchor-color-hover; // 連結 Hover 顏色

// 連結顏色：已去過的連結
// :visited 限制不能使用 opacity
$visited-blue: #73b5ff; // $primary-link opacity 0.7 color
$visited-green: #4cb794; // $primary-color opacity 0.7 color
$visited-dark: lighten($body-font-color, 20%);
$visited-black: lighten($black, 20%);
$visited-white: darken($white, 10%);

// 錯誤提醒顏色
$primary-error: map-get($foundation-palette, alert); // Foundation 設定的紅色

// 線段基礎顏色
// 資料表格最上與最下的線段顏色
$border-color-dark: #9c9c9c;

// 表格顏色
$table-odd-bg: rgba($body-font-color, 0.05); // 表格單數列背景顏色
$table-hover-bg: rgba($primary-color, 0.1); // 表格Hover背景色



// --------------------------------------------------------
// Function
// --------------------------------------------------------
// [Math] 開根號: Ref: https://www.antimath.info/css/sass-sqrt-function/
@function sqrt($r) {
	$x-0: 1;
	$x-1: $x-0;
	@for $i from 1 through 10 {
		$x-1: $x-0 - ($x-0 * $x-0 - abs($r)) / (2 * $x-0);
		$x-0: $x-1;
	}
	@return $x-1;
}



// 計算 em 為單位
/// @param {number} $num - 傳入數字 = 需計算的大小 / 文字大小
// 如375寬，文字距離下方21px，字型大小24px => em-calc(21 / 24)
@function em-calc($num) {
	@return $num * 1em;
}



// 取代 String
@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
	@if ($index) {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}



// [Color] 轉換 HEX 色碼為 RGB
@function hex-to-rgb($hex) {
	@return red($hex), green($hex), blue($hex);
}




// [Color] HEX 顏色是否接近白色/黑色：true === 接近白色；false === 接近黑色
// 概念：https://stackoverflow.com/a/9780689/11240898
@function color-close-white($hex) {
	$y: 0.2126 * red($hex) + 0.7152 * green($hex) + 0.0722 * blue($hex);
	@return $y >= 128;
}



// [Color] 取得顏色1(C1)與顏色2(C2)中間的顏色
// RGB 分開計算，計算新的R：C1 + 四捨五入{(C2 - C1) * 取得中間百分之幾的顏色}，GB一樣算法
// 概念 Ref(Javascript)：https://stackoverflow.com/a/61396704/11240898
// 其餘 SASS 參考: https://gist.github.com/voxpelli/8452877
// ★★★★★尚未解決問題：
// 1. 如何判定誰減誰
// 2. 出現負數的情況怎麼辦等
@function calc-center-color($fade, $color1, $color2) {
	$target-rgb: ();
	$color1-rgb: red($color1), green($color1), blue($color1);
	$color2-rgb: red($color2), green($color2), blue($color2);

	@for $i from 1 through 3 {
		$color1-current: nth($color1-rgb, $i);
		$color2-current: nth($color2-rgb, $i);
		$target-rgb: append($target-rgb, ( $color1-current + floor(($color2-current - $color1-current) * $fade) ));
	}
	@return rgb(nth($target-rgb, 1), nth($target-rgb, 2), nth($target-rgb, 3));
}



// --------------------------------------------------------
// Photoshop 轉換功能
// --------------------------------------------------------
// [Photoshop|PS] 的字距設定 1: 0.001em
// Photoshop Letter Tracking/1000 = letter-spacing in em
// Ref: https://martinwolf.org/before-2018/blog/2016/02/convert-typographic-properties-from-ps-to-css/
@function ps-letter-spacing($num) {
	@return $num / 1000 * 1em;
}


// Ref: https://github.com/kwaledesign/sass-shadows/blob/master/photoshop-shadows.scss
$ps-global-light: 120deg !default;
@function ps-shadow( $angle: $ps-global-light, $distance: 0, $spread: 0, $size: 0, $color: #000, $inner: false ) {
	// default to degrees, same as photoshop
	@if unitless($angle) {
		$angle: $angle * 1deg;
	}
  
	// compass has a bug converting degrees (fixed in Compass 0.12)
	// https://github.com/chriseppstein/compass/pull/666
	@if unit($angle) == 'deg' {
		// convert to radians to avoid issues
		$angle: ((180 - ($angle/1deg)) * pi() / 180);
	}
	//TODO: other angle units, including rad, will cause issues
  
	// SASS doesn't do percentages cleanly
	@if not(unitless($spread)) and unit($spread) == '%' {
		// remove the percentage unit
		$spread: $spread/1%;
	} @else if $spread < 0 {
		// correct for passing the spread as a decimal
		$spread: $spread * 100;
	}
  
	$h-shadow: round(cos($angle) * $distance);
	$v-shadow: round(sin($angle) * $distance);
	$css-spread: $size * ($spread/100);
	$blur: $size - $css-spread;
	$inset: if($inner != false, 'inset', '');

	// @return ( $h-shadow $v-shadow $blur $css-spread $color unquote($inset) );
	@return ( $h-shadow $v-shadow $blur $color unquote($inset) );
}

@function ps-text-shadow( $angle: $ps-global-light, $distance: 0, $spread: 0, $size: 0, $color: #000 ) {
	$shadow: ps-shadow($angle, $distance, $spread, $size, $color);
	@return (nth($shadow, 1) nth($shadow, 2) nth($shadow, 3) nth($shadow, 5));
}

@function ps-glow($choke: 0, $size: 0, $color: #fff, $inner: false) {
	@return ps-shadow(0, 0, $choke, $size, $color, $inner);
}



// --------------------------------------------------------
// Variable(2/2)
// --------------------------------------------------------
// 漸層
$primary-gradient-light-color: #aff8b5; // 預設漸層的亮色

// 主要漸層（詳細設定）
/// @param {number} $angle - 漸層角度
/// @param {string} $start-color - 起始顏色
/// @param {string} $start-position - 起始位置
/// @param {string} $end-color - 結束顏色
/// @param {string} $end-position - 結束位置
/// @param {string} $center-color-position - 中間顏色的位置（中間顏色用程式計算）
@function primary-gradient-set(
	$angle: 60, 
	$start-color: $primary-color, 
	$start-position: 0%, 
	$end-color: $primary-gradient-light-color, 
	$end-position: 100%, 
	$center-color-position: 50%
) {
	// 計算中間的顏色
	$center-color: calc-center-color(0.5, $start-color, $end-color);

	@return linear-gradient(#{$angle}deg, #{$start-color} #{$start-position}, #{$center-color} #{$center-color-position}, #{$end-color} #{$end-position});
}



// 主要漸層（簡易設定）
/// @param {string} $angle - 漸層角度
/// @param {string} $end-color - 亮色（100%的顏色）
// @include primary-gradient(角度, 結束的顏色);
@function primary-gradient($angle: 60, $end-color: $primary-gradient-light-color) {
	@return primary-gradient-set($angle, $primary-color, 30%, $end-color, 150%, 93%);
}

$primary-gradient: primary-gradient(); // 基本漸層


// 藍色漸層(Company - Board of Directors 模組 Subtitle 標題 Bar 為藍色)
$gb-angle: 45deg;
$gb-color1: #b5d9f1;
$gb-color2: #b7e5ff;
$gb-color3: #d6f0fe;
$gb-color1-5: calc-center-color(0.42, $gb-color1, $gb-color2);
$gb-color2-5: calc-center-color(0.5, $gb-color2, $gb-color3);
$primary-gradient-blue: linear-gradient($gb-angle, $gb-color1 0%, $gb-color1-5 15%, $gb-color2 36%, $gb-color2-5 69%, $gb-color3 100%);

// Production - Quality & Reliability Service (直接從PS上複製CSS設定)
$primary-gradient-blue2: linear-gradient(46deg, #179dc4 31%, #3ccab6 80%, #61f7a8 98%);

// Index News (直接從PS上複製CSS設定)
$primary-gradient-blue3: linear-gradient( 45deg, #b7e5ff 21%, #dbf2ff 43%, #f4f9ff 90%);

// -----------

// 陰影
$shadow-color: #262626;
$shadow-angle: 47deg;
$primary-shadow: ps-shadow($shadow-angle, 13px, 0, 10px, rgba($shadow-color, 0.13), false);
$primary-shadow-green: ps-shadow($shadow-angle, 13px, 0, 10px, rgba($primary-color, 0.43), false);
$primary-shadow-hover: ps-shadow($shadow-angle, 35px, 0, 30px, rgba($shadow-color, 0.13), false);
$primary-shadow-hover-green: ps-shadow($shadow-angle, 35px, 0, 20px, rgba($primary-color, 0.43), false);



// -----------

// 表格
$table-primary: $primary-gradient;
$table-gray: #aaa;

// padding % 的基準，是以內容100%寬為基準
// table td, th 使用 padding 的時候需注意，會壓縮到內容（不會增加內容分配的寬度）
// 內容可能會移出格子，占到其它格導致破版，要設定不同的欄位padding，建議設定為 before 寬來占位置
// 但用在 after 多行時沒有用
$table-padding-x: percentage($number: 1 / 12);
$table-padding-size: (
	xlarge: calc((100vw - #{rem-calc(100 * 2)}) / 12 - #{rem-calc(20)}), // xlarge 時內容 1/12
	xxxl: calc(#{rem-calc(1440)} / 12 - #{rem-calc(20)}) // 內容超過 max-width 時內容的 1/12
);





// --------------------------------------------------------
// Foundation 相關的變數
// --------------------------------------------------------
// Gutter / 2
$grid-margin-x-small: map-get($grid-margin-gutters, small) / 2; // Small
$grid-margin-x-medium: map-get($grid-margin-gutters, medium) / 2; // Medium+

// 取得 Foundation 字型設定
/// Quick sort
/// @param {String} $heading - (h1|h2|h3|h4|h5|h6)
/// @param {String} $bp - Breakpoint(small|medium)
/// @return {String}
@function zh-font-get($heading: 'h1', $bp: 'small') {
	@return rem-calc(map-get(map-get(map-get($header-styles, $bp), $heading), 'font-size'));
}



// --------------------------------------------------------
// Logo上的G
// --------------------------------------------------------
@function logo-g-builder($color: $primary-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 46.15 46.15'%3E%3Cpath fill='%23#{$color-normal}' d='M.664 23.074a17.013 17.013 0 0 0 16.961 16.964h13.339l2.614-4.524H19.889a9.282 9.282 0 0 1-7.184-3.661 13.854 13.854 0 0 1-2.995-8.779 13.853 13.853 0 0 1 2.995-8.774 9.285 9.285 0 0 1 7.184-3.661h23.024l2.574-4.523H17.625A17.012 17.012 0 0 0 .664 23.074m23.367 2.269h11.681v14.695h9.046V20.82H26.583z'/%3E%3C/svg%3E";
	@return $build-svg;
}
$logo-g-primary: logo-g-builder();
$logo-g-gray: logo-g-builder($black);



// --------------------------------------------------------
// Select Style
// --------------------------------------------------------
// Select Arrow
@function select-arrow-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: "data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2025%2025%22%3E%3Cpath%20fill%3D%22%23#{$color-normal}%22%20d%3D%22M12.501%2015.677l-9.197-4.562.888-1.792%208.309%204.122%208.307-4.122.888%201.792-9.195%204.562z%22%2F%3E%3C%2Fsvg%3E";
	@return $build-svg;
}
$select-arrow: select-arrow-builder();
$select-arrow-white: select-arrow-builder($white);

@mixin select-style {
	padding-right: rem-calc(40);
	background: #fff url(#{$select-arrow}) no-repeat right 12px center / 19px;
	cursor: pointer;

	@include breakpoint(medium) {
		padding-right: rem-calc(55);
		background-position: right 27px center;
	}
}


// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
@mixin selection-none {
	-webkit-touch-callout: none; // iOS Safari
	-webkit-user-select: none; // Safari
	-khtml-user-select: none; // Konqueror HTML
	-moz-user-select: none; // Firefox
	-ms-user-select: none; // Internet Explorer/Edge
	user-select: none; // Non-prefixed version, currently, supported by Chrome and Opera
}

// --------------------------------------------------------
// Mixin
// --------------------------------------------------------

// Flex
@mixin fc($justify, $align) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}

	@if $align {
		align-items: $align;
	}
}

// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin bem-tag($tag: 'a') {
	$current: &;

	@at-root #{$tag}#{$current} {
		@content;
	}
}

// 使用 ::before 的 padding-top 撐高度
@mixin before-img-size($width: 1, $height: 1, $build-other: true) {
	@if $build-other {
		content: '';
		display: block;
	}

	padding-top: percentage($height / $width);
}


// ---------------------------------------- [START] Media Query 相關
// Hover Media Query (使用 Foundation Mixin)
$project-has-ie: false; // 專案是否有含 IE => 有 IE 就不能使用 @media hover
@mixin breakpoint-hover($hover-enable: true) {
	@if $project-has-ie {
		// 有 IE 專案
		@if $hover-enable {
			@include breakpoint(xlarge) {
				@content;
			}
		}
	
		@else {
			@include breakpoint(large down) {
				@content;
			}
		}
	}

	@else {
		// 無 IE 專案
		@if $hover-enable {
			@media (hover: hover) and #{breakpoint(xlarge)} {
				@content;
			}
		}
	
		@else {
			@media (hover: none) and #{breakpoint(large down)} {
				@content;
			}
		}
	}
}

// Breakpoint Between (使用 Foundation Mixin)
@mixin breakpoint-between($start, $end) {
	@media screen and #{breakpoint($start)} and #{breakpoint($end down)} {
		@content;
	}
}

// Dark Mode
// 輸入[dark|light]，dark 為預設
@mixin mode($screen-mode: dark) {
	@media (prefers-color-scheme: #{$screen-mode}) {
		@content;
	}
}
// ---------------------------------------- [END] Media Query 相關



// ---------------------------------------- [START] Custom Scroll Bar
$scrollbar-thumb-color: $primary-color; // 操作顏色
$scrollbar-track-color: #eee; // 滾軸背景
$scrollbar-width: 6px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 6px; // 圓角，Firefox不支援

@mixin custom-scroll-bar ($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}
// ---------------------------------------- [END] Custom Scroll Bar



// ---------------------------------------- [START] Toggle Show Style
// Toggle Show Style
// https://stackoverflow.com/a/30531678
$d-opacity: 0.25s;
$d-disappear: 0.5s;
/// @param {string} $switch - 開關class
/// @param {string} $targetEl - 要縮起展開的物件（通常為<a> ）
// 放在要加的 "is-open"(開關Class) class 的物件上
// @include toggle-menu-item('開關Class', '內層展開收闔物件，通常為<a>')
@mixin toggle-menu-item($switch, $targetEl) {
	// Hide
	&:not(#{$switch}) {
		#{$targetEl} {
			margin: 0;
			padding-top: 0;
			padding-bottom: 0;
			font-size: 0;
			line-height: 0;
			color: transparent;
			border-width: 0;
			-webkit-speak: none;
			opacity: 0;
			transition: opacity $d-opacity,
						font-size $d-disappear $d-opacity, 
						line-height $d-disappear $d-opacity, 
						margin $d-disappear $d-opacity,
						padding $d-disappear $d-opacity,
						color $d-opacity;
		}
	}
	// Show
	&#{$switch} {
		#{$targetEl} {
			transition: font-size $d-opacity, 
						margin $d-opacity, 
						padding $d-opacity, 
						line-height $d-opacity, 
						color $d-opacity, 
						opacity $d-disappear $d-opacity;
		}
	}
}
// ---------------------------------------- [END] Toggle Show Style



// ---------------------------------------- [START] 缺角矩形／圖(hover)
@mixin box-lack-cornor-hover {
	.box-lack-cornor {
		&__shadow {
			&::before,
			&::after {
				box-shadow: $primary-shadow-hover;
			}
		}

		&__hover-line {
			transform: translateY(0);
		}

		.img {
			background-position-y: 50%;
		}
	}
}
// ---------------------------------------- [END] 缺角矩形／圖(hover)



// ---------------------------------------- [START] Animation Set
@mixin set-animate($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
			transition-duration: 0.5s;
			transition-property: opacity, transform;
			transition-delay: #{($i - 1) * 0.1s};
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

@mixin set-animate-flow($animateEl) {
	&.js-ani {
		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

// ---------------------------------------- [END] Animation Set
