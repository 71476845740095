// ========================================----------------------------------------------
// Main Style
// ========================================----------------------------------------------
@import 'layout/variable_mixin_function.scss';

// ========================================----------------------------------------------

/* Vendor */
@import 'vendor/swiper/swiper';

// ========================================----------------------------------------------

/* Component */

// ========================================----------------------------------------------

/* Container */

/* Banner */
.sec-index-banner {
	@include breakpoint(medium down) {
		padding-bottom: rem-calc(27);
	}

	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-banner__cover',
			'.index-banner__img-box',
			'.index-banner__view-text .grid-x';
	
	&.js-ani {
		@include set-animate($animate-el);

		.index-banner__cover {
			transform: translateX(-100px);
		}

		.index-banner__img-box {
			transform: translateX(100px);
		}

		.index-banner__view-text .grid-x {
			transform: translate(0);
		}

		.swiper-slide {
			&:not(.swiper-slide-active) {
				.index-banner__cont {
					opacity: 0; // 因為換成 Fade Effect，避免影響Active顯示
				}
			}
		}

		&.is-animated {
			@include set-animated($animate-el);

			.swiper-slide {
				&:not(.swiper-slide-active) {
					.index-banner__cont {
						// opacity: 1; // 增加這個一開始就會出現，不能增加，原因不明
						transition: opacity 1s 1s; // 不增加這個就會有閃爍
					}
				}
			}
		}
	}
	/* stylelint-enable */
}

.index-banner {
	$root: &;
	$img-margin-bottom-large: 50;

	position: relative;

	// 左邊遮罩
	&__cover {
		// clip-path 點
		// 三角函數參考：https://zh.numberempire.com/right_triangle_calculator.php
		// 1 ------- 2
		// |        /
		// 4------ 3
		$square-point-1: 0 0;
		$square-point-2: 100% 0;
		$square-point-3: 0 100%;
		$square-point-4: 0 50%;

		position: absolute;
		z-index: 4;
		top: 0;
		left: 0;
		width: percentage(175 / 375);
		height: 100%;
		background-color: $primary-color;
		clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4});

		@include breakpoint(large) {
			// clip-path 點
			// 三角函數參考：https://zh.numberempire.com/right_triangle_calculator.php
			// 1 ------- 2
			// |        /
			// 4------ 3
			$square-point-1: 0 0;
			$square-point-2: 100% 0;
			$square-point-3: calc(100% - #{100% / tan(66deg)}) 100%;
			$square-point-4: 0 100%;

			width: percentage(990 / 1920);
			clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4});
		}

		// 背景圖
		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			opacity: 0.25;
		}

		// 出現背景的時機調整
		@at-root %cover {
			&::before {
				background-image: url('../images/index/banner_mask.jpg');
			}
		}

		// A. Swiper 完成 Init
		@at-root #{$root} .swiper-container-initialized #{$root}__cover {
			@extend %cover;
		}

		// B. Lazyload loaded
		&.loaded {
			@extend %cover;
		}
	}

	&__cont {
		display: block;
		height: 100%;

		@include breakpoint(large) {
			&::before {
				content: '';
				position: absolute;
				z-index: 3;
				top: 0;
				left: 0;
				// width: 10%;
				width: percentage((1920 - 1300 + 5) / 1920);
				height: 100%;
				background: $white;
			}
		}
	}

	&__img-box {
		margin-left: auto;
		background: #333;

		&::before {
			padding-top: percentage(396 / 375);
		}

		@include breakpoint(large) {
			margin-bottom: rem-calc($img-margin-bottom-large);
			max-width: percentage(1300 / 1920);
			height: calc(100% - #{rem-calc($img-margin-bottom-large)});

			&::before {
				padding-top: percentage(870 / 1300);
			}

			// 左下遮罩
			&::after {
				content: '';
				position: absolute;
				bottom: -1px;
				left: -1px;
				display: block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 200px 0 0 100px;
				border-color: transparent transparent transparent $white;
			}
		}
	}

	&__img {
		&.swiper-lazy {
			opacity: 0;

			&.swiper-lazy-loaded {
				opacity: 1;
				transition: opacity 0.3s;
			}
		}
	}

	// Banner 內的文字
	&__text-box {
		position: absolute;
		z-index: 5;
		top: 50%;
		left: 50%;
		width: 100%;
		color: $white;
		transform: translate(-50%, -50%);
		word-break: break-all;
		word-break: break-word;
		box-sizing: border-box;

		@include breakpoint(small only) {
			padding-right: rem-calc(15);
			padding-left: rem-calc(15);
		}
		
		@include breakpoint(large) {
			margin-top: rem-calc($img-margin-bottom-large * -1 / 2); // 圖片下方距離
		}

		&__inside {
			opacity: 0;
			transform: translateX(#{rem-calc(50)});

			@include breakpoint(large) {
				width: percentage(8 / 12);
			}
		}
	}

	@at-root %banner-font {
		color: inherit;
	}

	&__title {
		@extend %banner-font;

		--line-height: 1.2;

		font-size: rem-calc(28);
		letter-spacing: ps-letter-spacing(60);
		text-shadow: 0 0 10px rgba($black, 0.5);

		@include breakpoint($xs-down) {
			font-size: rem-calc(24);
		}

		@include breakpoint(medium) {
			font-size: rem-calc(53);
		}
		
		@include breakpoint(large) {
			font-size: rem-calc(58);
		}
	}

	&__sub-title {
		@extend %banner-font;

		margin-bottom: rem-calc(20);
		text-shadow: 0 0 10px rgba($black, 0.5);

		@include breakpoint($xs-down) {
			font-size: rem-calc(18);
			font-weight: normal;
		}

		@include breakpoint(medium) {
			margin-bottom: rem-calc(40);
		}
	}

	// Swiper Setting
	.swiper-container {
		@include breakpoint(large) {
			margin-right: 0;
			margin-left: auto;
		}
		
		@include breakpoint(xxlarge) {
			$area-height: 40 + 80 + 65; // HeaderTop(40) + HeaderMain(80) + 區塊螢幕下方距離(65)

			height: calc(100vh - #{rem-calc($area-height)});
			min-height: rem-calc(600);
		}
	}

	.swiper-slide {
		overflow: hidden;
	}

	.swiper-slide-duplicate-active,
	.swiper-slide-active {
		.index-banner {
			&__text-box {
				&__inside {
					animation: show-text 0.5s 0.4s forwards;
				}
			}
		}
	}

	@keyframes show-text {
		0% {
			opacity: 0;
			transform: translateX(#{rem-calc(50)});
		}

		100% {
			opacity: 1;
			transform: translateX(0);
			
		}
	}

	// Control
	// Wrapper
	.swiper-nav {
		@include breakpoint(large) {
			position: absolute;
			z-index: 10;
			top: 50%;
			right: 60px;
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-top: rem-calc(-30);
			transform: translateY(-50%);
			animation: s1_navShow_l 4.5s;
		}

		@keyframes s1_navShow_s {
			0%,
			66.66% {
				opacity: 0;
			}

			100% {
				opacity: 1;
			}
		}

		@keyframes s1_navShow_l {
			0%,
			66.66% {
				opacity: 0;
				transform: translate(100%, -50%);
			}

			100% {
				opacity: 1;
				transform: translate(0%, -50%);
			}
		}
	}

	%banner-control {
		@include breakpoint(large) {
			position: relative;
			top: auto;
			bottom: auto;
			right: auto;
			left: auto;
			display: block;
			margin-top: 0;
			transform: none;
	
			&::after {
				position: absolute;
				top: 50%;
				left: 50%;
				margin: 0;
				transform: translate(-50%, -50%);
			}
		}
	}

	// Nav - Pagination
	.swiper-pagination {
		@extend %banner-control;

		@include breakpoint(medium down) {
			bottom: -52px;
		}
		
		@include breakpoint(large) {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: rem-calc(17);
			margin-bottom: rem-calc(17);
		}
	}

	.swiper-pagination-bullet {
		@include breakpoint(large) {
			margin: 0 2px;
			width: 6px;
			height: 6px;
			background: $white;
			border-color: $white;
			box-shadow: 0 0 2px $black;
	
			&:not(.swiper-pagination-bullet-active) {
				opacity: 0.4;
			}
		}
	}

	// Nav - Arrow
	.swiper-button-prev,
	.swiper-button-next {
		@extend %banner-control;

		&::after {
			color: $white;
		}

		@include breakpoint(medium down) {
			display: none;
		}
	}

	.swiper-button-next {
		svg {
			$size: round(37 * 44 / 32 / 44 * 100% * 100) / 100;

			position: absolute;
			top: ($size - 100) / 2 * (-1);
			left: ($size - 100) / 2 * (-1);
			width: $size;
			height: $size;
			transform: rotate(-90deg);
		}

		.circle {
			position: absolute;
			z-index: 52;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			canvas {
				display: block;
			}
		}
	}
	// End Control

	// Fixed Swiper Loop LCS Bug
	@include swiper-fixed-loop-lcs;
}



// ========================================



/* Solutions */
.sec-index-solution {
	@include breakpoint(medium down) {
		padding-top: rem-calc(40 + 56);
	}
	
	@include breakpoint(large) {
		margin-top: rem-calc(-60);
		padding-top: calc(var(--padding-y) + #{rem-calc(60 + 100)}); // MarginTop(60) * -1 + 調整位置(100)
	}

	.bg-box {
		&__bg {
			&.bg-1 {
				top: 0;
				background-position: right top;
			}

			&.bg-2 {
				bottom: -104px;
				background-position: left bottom;
			}

			@include breakpoint(medium down) {
				display: none;
			}
		}
	}

	.sec-title {
		&__title {
			margin-bottom: rem-calc(8);
		}
	}

	/* Animation */
	/* stylelint-disable */
	$animate-el: '.sec-title',
			'.index-solution__item:nth-child(1)',
			'.index-solution__item:nth-child(2)',
			'.index-solution__item:nth-child(3)';
	&.js-ani {
		@include set-animate($animate-el);

		.bg-1,
		.bg-2 {
			opacity: 0;
			transition: opacity 1s;
		}

		&.is-animated {
			@include set-animated($animate-el);

			.bg-1,
			.bg-2 {
				opacity: 1;
			}
		}
	}
	/* stylelint-enable */
}



.index-solution {
	$root: &;

	&__list {
		list-style: none;
	}

	&__item {
		@include breakpoint(medium down) {
			&:not(:last-child) {
				margin-bottom: rem-calc(26);
			}
		}
	}

	&__link {
		display: block;
		padding: rem-calc(20);
		color: $body-font-color;
		
		&:hover {
			color: $body-font-color;
		}

		@include breakpoint(medium down) {
			margin-right: rem-calc(-20);
			margin-left: rem-calc(-20);
		}

		@include breakpoint(xlarge) {
			padding: rem-calc(55 40);
		}
	}

	&__title {
		text-align: center;
		// text-transform: uppercase;
		color: $black;
	}

	&__img-box {
		margin-bottom: rem-calc(15);

		@include breakpoint(medium) {
			max-width: 142px;
		}

		.hover {
			display: none; // Hide for small
		}

		&__img {
			&.hover {
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				pointer-events: none;
			}
		}

		&__svg {
			&.hover {
				opacity: 0;
			}
		}
	}

	@include breakpoint-hover {
		&__img-box {
			.normal,
			.hover {
				transition: opacity 0.3s;
			}

			.hover {
				display: block;
			}
		}

		&__link {
			position: relative;
			overflow: hidden;
			transition: box-shadow 0.3s;

			// Hover後顯示的圖案
			&::before,
			&::after {
				content: '';
				position: absolute;
				transition: transform 0.3s;
			}

			&::before {
				top: 0;
				left: 0;
				width: 61px;
				height: 109px;
				background-image: url('../images/index/solution_hover_deco1.png');
				transform: translate(-100%, -100%);
			}

			&::after {
				bottom: 0;
				right: 0;
				width: 109px;
				height: 151px;
				background-image: url('../images/index/solution_hover_deco2.png');
				transform: translate(100%, 100%);
			}

			// Hover
			&:hover,
			&:focus {
				color: $body-font-color;
				box-shadow: ps-shadow($shadow-angle, 35px, 0, 60px, rgba($shadow-color, 0.13), false);

				&::before,
				&::after {
					transform: translate(0, 0);
					transition-delay: 0.1s;
				}

				.text-arrow {
					--move-x: 20px;
				}

				#{$root} {
					&__img-box {
						.normal {
							opacity: 0;
						}

						.hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}
}



// ========================================



/* News */
.sec-index-news {
	z-index: 1;

	@include breakpoint(medium down) {
		padding-top: calc(var(--padding-y) - #{rem-calc(13)});
	}
	
	@include breakpoint(large) {
		margin-top: rem-calc(-70);
		margin-bottom: rem-calc(142);
	}

	.bg-box {
		// 遮罩外框
		@include breakpoint(large) {
			// clip-path 點
			//      1 --- 2
			//     /      |
			//    /       |
			//   /        |
			//  /         |
			// 4 -------- 3
			$square-point-1: percentage(260 / 812) 0;
			$square-point-2: 100% 0;
			$square-point-3: 100% 100%;
			$square-point-4: 0 100%; // 因為要三角函數計算，所以更改角度後，無法自動產生數字

			top: 130px;
			right: 0;
			left: auto;
			max-width: 812px;
			height: auto;
			background-position: right top;
			transform: none;
			clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4});

			// 裝飾背景
			&::before,
			&::after {
				@include before-img-size(812, 633, true);

				background: no-repeat center center / cover;
			}

			// 裝飾背景 - 1921+ 鏡射
			&::after {
				display: none;
			}

			&.loaded-content {
				&::before,
				&::after {
					background-image: url('../images/index/news_bg_deco.png');
				}
			}
		}

		&__bg {
			top: 50%;
			background-position: right center;
			transform: translateY(-50%);

			@include breakpoint(medium down) {
				background-size: auto 100%;
			}

			@include breakpoint(large) {
				top: ((100 - percentage(995 / 633)) / 2);
				height: percentage(995 / 633);
				transform: none;
			}

			// 背景 - 1921+ 鏡射
			&.reflection {
				display: none;
			}
		}
	}

	// 1921+ 背景部分
	@include breakpoint(1921) {
		// 白色遮罩
		&::before {
			content: '';
			position: absolute;
			z-index: 5;
			top: 0;
			right: 0;
			display: block;
			width: calc((100% - 1920px) / 2);
			height: 100%;
			background: linear-gradient(to left, $white 0%, rgba($white, 0) 100%);
			pointer-events: none;
		}
		
		.bg-box {
			max-width: calc(812px + (100% - 1920px) / 2);

			&::before,
			&::after {
				padding: 0;
				width: 812px;
				height: 633px;
			}

			// 裝飾背景 - 1921+ 鏡射
			&::after {
				position: absolute;
				top: 0;
				left: 811px;
				display: block;
				transform: scaleX(-1);
			}

			&__bg {
				width: 812px;

				// 背景 - 1921+ 鏡射
				&.reflection {
					left: 405px;
					display: block;
					transform: translate(50%) scaleX(-1);
				}
			}
		}
	}

	.sec-title {
		margin-bottom: rem-calc(30);

		@include breakpoint(large) {
			margin-bottom: rem-calc(67);
		}
	}



	/* Animation */
	/* stylelint-disable */
	$animate-el: '.bg-fake',
			'.sec-title',
			'.swiper-box';
	&.js-ani {
		@include set-animate($animate-el);

		.bg-box__bg-box {
			opacity: 0;
			transition: opacity 1s;
		}

		&.is-animated {
			@include set-animated($animate-el);

			.bg-box__bg-box {
				opacity: 1;
			}
		}
	}
	/* stylelint-enable */
}



// Carousel Container
.index-news {
	$root: &;

	// clip-path 點
	// 1 ------- 2
	// |         |
	// |         |
	// |         3
	// |       ╱
	// 5-----4
	$cornor-triangle-height: 40px; // 右下三角形的高(Button高)

	$square-point-1: 0 0;
	$square-point-2: 100% 0;
	$square-point-3: 100% calc(100% - #{$cornor-triangle-height});
	$square-point-4: calc(100% - #{sin(30deg) * ($cornor-triangle-height / cos(30deg)) }) 100%; // 因為要三角函數計算，所以更改角度後，無法自動產生數字
	$square-point-5: 0 100%;

	padding-bottom: 40px;

	&__item {
		// filter: drop-shadow($primary-shadow);

		@include breakpoint(large) {
			height: 100% !important;
		}
	}

	&__link {
		$start-color: #b7e5ff;
		$end-color: $white;
		$center-color: calc-center-color(0.5, $start-color, $end-color);

		--padding: #{rem-calc(30)};
		--btn-height: #{rem-calc(40)};

		display: block;
		overflow: hidden;
		padding: var(--padding);
		padding-bottom: calc(var(--padding) + var(--padding) + #{rem-calc(30)});
		word-break: break-word;
		color: $white;
		background: $primary-gradient;
		clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4}, #{$square-point-5});

		&,
		&:hover,
		&:focus {
			color: $white;
		}

		&:visited {
			$news-white-text-opacity-08: #f4fbf8; // white opacity 0.8
			$news-white-text-opacity-09: #d1eae1; // white opacity 0.9
			$news-white-text-opacity-095: #f4fbf8; // white opacity 0.8

			color: $news-white-text-opacity-09;

			#{$root} {
				&__title,
				&__tag {
					color: $news-white-text-opacity-095;
				}
			}

			@include breakpoint(large) {
				color: $visited-dark;
				
				#{$root} {
					&__title,
					&__tag {
						color: $visited-black;
					}
				}
			}

		}

		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: left bottom;
			// background-position: -20px 35px; // 對照設計稿準確的位置（與News使用的素材統一），但會有很多BUG
			background-repeat: no-repeat;
			background-size: (730px / 2);
		}

		// swiper 讀取完後才載入背景圖片
		@at-root #{$root} {
			.swiper-container-initialized {
				#{$root} {
					&__link {
						// 白色
						--img: url('../images/news_card_alpha_2x.png');
						--img-bg: #{$primary-gradient-blue3}; // 漸層：background-image

						// 綠色
						--img-hover: url('../images/news_card_alpha_hover_2x.png');
						--img-hover-bg: #{$primary-gradient}; // 漸層：background-image

						background-image: var(--img-hover-bg);

						// 背景圖片
						&::before {
							background-image: var(--img-hover);
						}

						@include breakpoint(large) {
							background-image: var(--img-bg);

							&::before {
								background-image: var(--img);
							}
						}

						// Hover
						@include breakpoint-hover {
							&:hover,
							&:focus {
								background-image: var(--img-hover-bg);

								&::before {
									background-image: var(--img-hover);
									animation: bg-flicker 2s infinite;
								}
							}
						}
					}
				}
			}
		}

		@keyframes bg-flicker {
			50% {
				opacity: 0.5;
			}
		}
		
		@include breakpoint(medium) {
			$cornor-triangle-height: 54px; // 右下三角形的高(Button高)

			$square-point-3: 100% calc(100% - #{$cornor-triangle-height});
			$square-point-4: calc(100% - #{sin(30deg) * ($cornor-triangle-height / cos(30deg)) }) 100%; // 因為要三角函數計算，所以更改角度後，無法自動產生數字

			--padding: #{rem-calc(40)};
			--btn-height: #{rem-calc(54)};

			clip-path: polygon(#{$square-point-1}, #{$square-point-2}, #{$square-point-3}, #{$square-point-4}, #{$square-point-5});
		}

		@include breakpoint(large) {
			height: 100%;
			box-sizing: border-box;
			background-image: linear-gradient(45deg, #{$start-color} 21%, #{$center-color} 43%, #{$end-color} 90%);

			&,
			&:hover,
			&:focus {
				color: $body-font-color;
			}
		}
	}

	&__text-box {
		$tag-max-line: 1;
		$tag-font-size: 16;
		$tag-line-height: 1.6;
		$title-max-line: 3;
		$title-font-size: 26;
		$title-max-line: 1.2;
		$desc-max-line: 6;
		$desc-font-size: 18;
		$desc-line-height: 1.2;

		position: relative;
		z-index: 1;
		height: 240px;

		@include breakpoint(medium) {
			height: 285px;
		}
	}

	@at-root %index-new-title-color {
		color: $white;

		@include breakpoint(large) {
			color: $black;
		}
	}

	&__title {
		@extend %index-new-title-color;

		--line-height: 1.2;

		margin-bottom: em-calc(24 / 26);
		// text-transform: uppercase;
	}
	
	&__tag {
		@extend %index-new-title-color;

		margin-bottom: em-calc(19 / 16);
		font-weight: normal;
	}

	&__desc {
		--line-height: 1.2;

		margin-bottom: 0;
	}

	&__button {
		// --skew: 30deg; // 改寫於 .angle-30 樣式

		position: absolute;
		z-index: 5;
		bottom: 0;
		right: -1px;
		margin-bottom: 0;
		margin-right: var(--move-margin);
		margin-left: 0;
		min-width: 0;

		&,
		&:hover {
			color: $primary-color;
			background: $white;
		}

		@include breakpoint(large) {
			color: $black;
		}
	}

	// 陰影
	// 原本使用 clip-path + filter drop-shadow
	// 但 drop-shadow 光是一般呈現更換輪播就會 Lag
	// 要 transition 就不得了
	// 所以改用其他方式呈現
	&__shadow {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&::before,
		&::after {
			content: '';
			position: absolute;
			left: 0;
			box-shadow: $primary-shadow;
			@include breakpoint-hover {
				transition: box-shadow 0.3s;
			}
		}
		
		&::before {
			top: 0;
			width: 100%;
			height: calc(100% - #{rem-calc(54)});
		}
		
		&::after {
			$h: 40px;
			$skew: 30deg;

			--move-margin: #{(sin($skew) * ($h / cos($skew)))};

			bottom: 0;
			width: calc(100% - 40px);
			width: calc(100% - var(--move-margin) - 9px);
			height: rem-calc(54);
		}
	}

	@include breakpoint-hover {
		&__item {
			// transition: filter 0.3s; // 太lag，換個方式呈現

			&:hover,
			&:focus-within {
				// filter: drop-shadow($primary-shadow-hover-green);

				#{$root} {
					&__shadow {
						&::before,
						&::after {
							box-shadow: $primary-shadow-hover-green;
						}
					}
				}
			}
		}

		&__link {
			$start-color: #b7e5ff;
			$end-color: $white;
			$center-color: calc-center-color(0.5, $start-color, $end-color);

			background-image: linear-gradient(45deg, #{$start-color} 21%, #{$center-color} 43%, #{$end-color} 90%);

			&:hover,
			&:focus {
				color: $white;
				background-image: $primary-gradient;

				#{$root} {
					&__title,
					&__tag {
						color: $white;
					}

					&__button {
						--move-x: 20px;

						color: $primary-color;
					}
				}
			}
		}
	}

	// Swiper Setting
	.swiper-box {
		--margin-x: #{rem-calc(40)};
		--margin-y: #{rem-calc(40)};
		
		margin: calc(var(--margin-y) * -1) calc(var(--margin-x) * -1);
		
		@include breakpoint(medium) {
			--margin-x: #{rem-calc(70)};
		}

		@include breakpoint(xlarge) {
			--margin-x: #{rem-calc(110)};
			--margin-y: #{rem-calc(70)};
		}

		@include breakpoint(xxlarge) {
			width: percentage($number: (1160 + 110 * 2) / 1440);

			// 右側遮罩（因為有背景圖，項目又有陰影，只能用 Mask）
			mask-image: linear-gradient(to right, $black calc(100% - 100px), transparent 100%);

			// 白色遮罩
			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				top: 0;
				left: 0;
				display: block;
				width: 110px;
				height: 100%;
				background: linear-gradient(to right, $white 0%, rgba($white, 0) 100%);
				pointer-events: none;
			}
		}
	}

	.swiper-container {
		margin-bottom: calc(var(--margin-y) * -1);
		padding: var(--margin-y) var(--margin-x);

		// 尚未建立 Swiper 的樣式
		&:not(.swiper-container-initialized) {
			.swiper-slide {
				margin-right: rem-calc(40);
				opacity: 1;

				@include breakpoint(large) {
					margin-right: rem-calc(30);
					width: calc((100% - #{rem-calc(30) * 2}) / 3);
					
					&:nth-child(n + 4) {
						opacity: 0;
					}
				}
			}
		}
	}

	// Slide
	@include breakpoint(medium down) {
		.swiper-slide {
			opacity: 0.3;
		}

		.swiper-slide-active {
			opacity: 1;
		}
	}

	@include breakpoint(large) {
		.swiper-slide {
			opacity: 0;
			pointer-events: none;
		}

		.swiper-slide-next,
		.swiper-slide-next + .swiper-slide,
		.swiper-slide-active {
			opacity: 1;
			pointer-events: auto;
		}
	}

	// Navigation
	.swiper-button-prev,
	.swiper-button-next {
		--padding-x: 40px;
	}

	.swiper-button-prev {
		left: calc(var(--padding-x) - var(--swiper-navigation-size) / 2); // 邊距 - 按鈕大小
	}
	
	.swiper-button-next {
		right: calc(var(--padding-x) - var(--swiper-navigation-size) / 2); // 邊距 - 按鈕大小
	}

	@include breakpoint(medium) {
		.swiper-button-prev,
		.swiper-button-next {
			--padding-x: 70px;
		}
	}

	@include breakpoint(xlarge) {
		.swiper-button-prev,
		.swiper-button-next {
			--padding-x: 110px;
		}
	}

	// Pagination
	.swiper-pagination {
		bottom: -11px;

		@include breakpoint(xlarge) {
			display: none;
		}
	}
}
// END. .index-news



// ========================================



/* Core */
.sec-index-core {
	@include breakpoint(medium down) {
		padding-top: calc(var(--padding-y) + #{rem-calc(40)});
	}
	
	@include breakpoint(large) {
		// --padding-y: calc(((螢幕寬) - 左右padding) * 圖片區百分比 * 圖片區高 / 圖片區寬 - 內容高) / 2);
		--padding-y: calc(((100vw - 110px) * 0.5 * 725 / 775 - 280px) / 2);

		margin-bottom: rem-calc(102);
	}

	@include breakpoint(1660) {
		--padding-y: #{rem-calc(260)};
	}

	// Background
	.bg-box {
		&__bg {
			bottom: -50%;
			left: 0;
			height: 200vh;
			background-position: bottom left;

			@include breakpoint(medium down) {
				bottom: -19%;
				background-size: 70vw;
				transform: scaleX(-1);
			}
		}
	}

	.sec-title {
		@include breakpoint(medium down) {
			margin-bottom: rem-calc(10 + 21);
		}
	}

	.sec-text-box {
		margin-bottom: 0;

		&__desc {
			@include breakpoint(medium down) {
				margin-bottom: rem-calc(5 + 16);
			}
		}
	}

	.button {
		margin-bottom: 0;
	}



	/* Animation */
	/* stylelint-disable */
	$animate-el: '.bg-box_bg', // 假的class，為了預留延遲時間
			'.sec-title',
			'.sec-text-box',
			'.index-core__img-box__img';
	&.js-ani {
		@include set-animate($animate-el);

		.bg-box {
			&__bg {
				opacity: 0;
			}
		}

		&.is-animated {
			@include set-animated($animate-el);

			.bg-box {
				&__bg {
					opacity: 1;
					transition: opacity 0.3s;
				}
			}
		}
	}
	/* stylelint-enable */
}



.index-core {
	$root: &;

	.grid-x {
		position: relative;
	}

	&__img-box {
		--img-width: 935; // 圖片寬
		--img-height: 886; // 圖片高
		--img-padding: 80; // 上下左右留的邊距
		--img-padding-x: 80; // 上下左右留的邊距
		--img-padding-y: 84; // 上下左右留的邊距

		position: relative;
		display: block;
		margin: 0 auto;
		margin-bottom: rem-calc(28);
		max-width: 468px;

		&::before {
			// 維持圖片大小尺寸
			content: '';
			display: block;
			padding-top: calc((var(--img-height) - var(--img-padding) * 2) / (var(--img-width) - var(--img-padding) * 2) * 100%);
		}
		
		@include breakpoint(large) {
			position: absolute;
			top: 50%;
			right: $grid-margin-x-medium;
			margin-bottom: 0;
			width: 50%;
			max-width: 935px;
			transform: translateY(-50%);
		}

		@include breakpoint-hover {
			transform: translateY(-50%) scale(1);
			transition: transform 0.5s;

			&:hover,
			&:focus {
				transform: translateY(-50%) scale(0.9);
			}
		}

		&__img {
			--width: calc((var(--img-width) * 100) / (var(--img-width) - var(--img-padding) * 2) * 1%);
			
			position: absolute;
			top: calc((100 * var(--img-padding)) / (var(--img-height) - var(--img-padding) * 2) * -1%);
			left: calc((100 * var(--img-padding)) / (var(--img-width) - var(--img-padding) * 2) * -1%);
			margin-top: 1px; // 因為會有些微偏差，所以用 margin-top 來推
			margin-left: 1px; // 因為會有些微偏差，所以用 margin-left 來推
			width: var(--width);
			max-width: var(--width);

			&:not(.loaded) {
				width: 100%;
				height: 100%;
			}
		}
	}
}
// END. .index-core



// ========================================



/* About */
.sec-index-about {
	z-index: 1;

	.sec-title {
		margin-bottom: rem-calc(21 + 8);

		@include breakpoint(medium) {
			margin-bottom: rem-calc(40);
		}

		@include breakpoint(large) {
			margin-bottom: rem-calc(88);
		}
	}

	.bg-box {
		&__bg {
			&.bg-1 {
				top: 0;
				background-position: right top;
			}

			&.bg-2 {
				bottom: 0;
				background-position: left bottom;
			}

			@include breakpoint(medium down) {
				display: none;
			}
		}
	}



	/* Animation */
	/* stylelint-disable */
	$animate-el: '.bg-box__bg',
			'.sec-title',
			'.index-about__item:nth-child(1)',
			'.index-about__item:nth-child(2)',
			'.index-about__item:nth-child(3)';
	&.js-ani {
		@include set-animate($animate-el);

		.bg-1,
		.bg-2 {
			opacity: 0;
			transition: opacity 1s;
		}

		&.is-animated {
			@include set-animated($animate-el);

			.bg-1,
			.bg-2 {
				opacity: 1;
			}
		}
	}
	/* stylelint-enable */
}

.index-about {
	$root: &;

	--gutter: #{rem-calc(40)};
	--margin-x: calc(var(--gutter) / 2);
	--col: 1;

	@include breakpoint(medium) {
		--col: 2;
	}

	@include breakpoint(large) {
		--col: 3;
	}

	@include breakpoint(xlarge) {
		--gutter: #{rem-calc(125)};
	}

	&__cont {
		margin-right: calc(var(--margin-x) * -1);
		margin-left: calc(var(--margin-x) * -1);

		@include breakpoint(large) {
			margin-bottom: rem-calc(-50);
		}

		> .cell {
			margin-right: var(--margin-x);
			margin-left: var(--margin-x);
			width: calc(100% / var(--col) - var(--margin-x) * 2);
		}
	}

	// 區塊
	&__item {
		margin-bottom: rem-calc(68);
		@include breakpoint(small only) {
			&:last-child {
				margin-bottom: 0;
			}
		}

		@include breakpoint(large) {
			&:nth-child(3n + 2) {
				margin-top: rem-calc(50);
			}
		}
	}

	// <a>
	&__link {
		display: block;
		color: $primary-color;
	}

	// ※與DOM順序不同，文字先
	// 標題
	&__title {
		margin-bottom: em-calc(8 / 20);
		// text-transform: uppercase;
		color: $black;
		
		@include breakpoint(large) {
			margin-bottom: em-calc(16 / 26);
		}
	}
	
	// 圖片
	&__img-box {
		$el: &;

		// $img-width: 416; // 圖片寬
		// $img-height: 483; // 圖片高
		// $img-padding: 25; // 上下左右留的邊距

		--img-width: 1013; // 圖片寬
		--img-height: 650; // 圖片高
		--img-padding: 50; // 上下左右留的邊距

		position: relative;
		margin-bottom: rem-calc(18);

		@include breakpoint(large) {
			--img-width: 416; // 圖片寬
			--img-height: 483; // 圖片高
			--img-padding: 25; // 上下左右留的邊距

			margin-bottom: rem-calc(44);
		}
		
		&::before {
			// 維持圖片大小尺寸
			content: '';
			display: block;
			// padding-top: percentage(($img-height - $img-padding * 2) / ($img-width - $img-padding * 2));
			padding-top: calc((var(--img-height) - var(--img-padding) * 2) / (var(--img-width) - var(--img-padding) * 2) * 100%);
		}

		&__img {
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
	
			&.bg,
			&.mobile {
				// $width: #{($img-width * 100) / ($img-width - $img-padding * 2) * 1%};
				--width: calc((var(--img-width) * 100) / (var(--img-width) - var(--img-padding) * 2) * 1%);
	
				// top: #{((100 * $img-padding) / ($img-height - $img-padding * 2) * -1%)};
				// left: #{((100 * $img-padding) / ($img-width - $img-padding * 2) * -1%)};
				top: calc((100 * var(--img-padding)) / (var(--img-height) - var(--img-padding) * 2) * -1%);
				left: calc((100 * var(--img-padding)) / (var(--img-width) - var(--img-padding) * 2) * -1%);
				margin-top: 1px; // 因為會有些微偏差，所以用 margin-top 來推
				margin-left: 1px; // 因為會有些微偏差，所以用 margin-left 來推
				width: var(--width);
				max-width: var(--width);
			}

			&.bg {
				display: none; // Hide for Mobile
			}
	
			&.hover {
				// $hover-img-width: 252;
				--hover-img-width: 252;

				display: none;
				// width: #{($hover-img-width * 100) / ($img-width - $img-padding * 2) * 1%};
				width: calc((var(--hover-img-width) * 100) / (var(--img-width) - var(--img-padding) * 2) * 1%);
			}

			@include breakpoint(large) {
				&.bg {
					display: block;
				}

				&.mobile {
					display: none;
				}
			}
		}

		&__inside {
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
			width: 100%;
			height: 100%;
		}
	}
	// END. &__img-box

	@include breakpoint-hover {
		&__img-box {
			&__img {
				&.hover {
					display: block;
					opacity: 0;
					transform: translateY(#{rem-calc(-50)});
					transition: opacity 0.3s, transform 0.3s;
				}
			}
		}

		// Hover Style
		&__link {
			&:hover,
			&:focus {
				#{$root} {
					// 圖片
					&__img-box {
						&__img {
							&.hover {
								opacity: 1;
								transform: translateY(0);
							}
						}
					}
				}

				// Learn More 箭頭
				.text-arrow {
					--move-x: 20px;
				}
			}
		}
	}
}
// END. .index-about



// ========================================



/* GLink */
.sec-index-glink {
	padding-bottom: rem-calc(238);

	@include breakpoint(small only) {
		padding-top: calc(var(--padding-y) - #{rem-calc(7)});
	}

	@include breakpoint(medium) {
		padding-bottom: rem-calc(180);
	}

	// 背景物件
	.bg-box {
		$el: &;

		&.bg-ft {
			.bg-box {
				&__bg {
					// 會被原本的樣式蓋過，所以還要再加一層 &.bg-ft
					@include breakpoint(1439 down) {
						height: calc(100% + 400px);
						background-position: right 20% bottom;
						background-size: auto 100%;
					}

					@include breakpoint(small only) {
						height: calc(100% + 200px);
					}

					@include breakpoint(1921) {
						left: calc((100vw - 1920px) / 2);
						max-width: 1920px;
					}

					&.is-clone {
						display: none;

						@include breakpoint(1921) {
							display: block;
							transform: scale(-1, 1);
							transform-origin: right center;
						}
					}
				}
			}
		}

		&__bg {
			// 遮罩
			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
			}

			// 背景區上方遮罩
			&::before {
				width: 100%;
				height: 400px;
				background: linear-gradient(to bottom, $white 0%, rgba($white, 0) 100%);
			}

			// 背景區左方遮罩
			&::after {
				width: 100%;
				height: 100%;
				background: linear-gradient(to right, rgba($white, 0.9) 0%, rgba($white, 0.8) 30%, rgba($white, 0) 100%);
			}
		}
	}

	@at-root %glink-text-shadow {
		text-shadow: 0 0 20px $white, 0 0 5px $white, 0 0 2px $white;
	}

	// 區塊標題
	.sec-title {
		&__title {
			@extend %glink-text-shadow;
		}
	}

	// 內容區域
	.cell.large-8 {
		position: relative;

		// 內容區域的中心圓白背遮罩
		&::before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			width: 120%;
			height: calc(1440px * #{8 / 12 * 1.2});
			background: radial-gradient($white 0%, rgba($white, 0) #{(100% / sqrt(2))});
			transform: translate(-50%, -50%);
		}
	}

	.sec-text-box {
		@include breakpoint(medium only) {
			max-width: 60%;
		}

		&__desc {
			@extend %glink-text-shadow;
		}
	}

	/* Animation */
	/* stylelint-disable */
	$animate-el: '.bg-box__bg',
			'.sec-title',
			'.sec-text-box';
	&.js-ani {
		@include set-animate($animate-el);

		@include breakpoint(1921) {
			.bg-box__bg {
				transform: none;
			}
		}

		&.is-animated {
			@include set-animated($animate-el);
		}
	}
	/* stylelint-enable */
}
